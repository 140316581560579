<template>
    <div class="card">
        <div class="card-header">
            <p class="card-title"> Facturación Albaranes</p>
        </div>
        <div class="card-body">
                <div class="row"> 
                    <div class="col-md-4">
                        <label for="">Empresa</label>
                        <select v-model="empresaselec" class="form-control" name="" id="empr">
                            <option value="">Seleccione empresa</option>
                            <option v-for="empr in empresas" :key="empr.id" :value="empr.id">{{ empr.nombre_empresa }}</option>
                        </select>
                    </div>    
                    <div class="col-md-4">
                        <label for="">Compañía</label>
                        <MultiSelect style="width: 100%;" v-model="companiasSeleccionadas" optionLabel="nombre" :options="companias" filter
                            :maxSelectedLabels="1" class="w-full md:w-20rem" placeholder="Seleccione compañía">
                            <template #option="slotProps">
                                {{ slotProps.option.nombre }}({{slotProps.option.prefijo}})
                            </template>
                        </MultiSelect>
                    </div>    
                    <div class="col-md-4 row" style="gap: 30px;">
                        <div>
                            <label for="">Fecha inicio</label>
                            <input class="form-control" type="date" v-model="fecha_inicio">
                        </div>
                        <div>
                            <label for="">Fecha fin</label>
                            <input class="form-control" type="date" v-model="fecha_fin">
                        </div>
                    </div>                               
                </div>
        </div>
    </div>
    <div class="card card-default card-tabs">
        <div class="card-header p-1 pt-1">
            <ul class="nav nav-pills" ref="tabHeaders">
                <li  class="nav-item">
                    <a class="nav-link active" href="#busqueda" data-toggle="tab">
                        Búsqueda
                    </a>
                </li>
                <li  class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#expedientes">
                        Números expedientes
                    </a>
                </li>
                <li v-if="mostrarCSV" class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#importcsv">
                        Importación CSV
                    </a>
                </li>
                <li v-if="mostrarCSVLD"  class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#importcsvld">
                        Importación CSV LD
                    </a>
                </li>
                <li v-if="mostrarPDF"  class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#importpdf">
                        Importación PDF
                    </a>
                </li>
            </ul>
        </div>
        <div class="card-body">
            <div class="tab-content" ref="tabContents">
                <div class="tab-pane active container-grid" id="busqueda">
                    <table class="table">
                        <thead>
                            <tr>
                                <th style="text-align: center;">
                                    Número Albarán
                                </th>
                                <th style="text-align: center;">
                                    Facturado
                                </th>
                                <th style="text-align: center;">
                                    Búsqueda Nombre
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="text-align: center;">
                                    <input v-model="numeroAlbaran" class="form-control" type="text">
                                </td>
                                <td style="text-align: center;">
                                    <select class="form-control" v-model="facturadoselec" id="">
                                        <option value="">Todos</option>
                                        <option value="SI">SI</option>
                                        <option value="NO">NO</option>
                                    </select>
                                </td>
                                <td  style="text-align: center;">
                                    <input class="form-control" v-model="busquedaNombre" type="text">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="display: flex; justify-content: center;">
                        <button @click="mostrarAlbaranesBusqueda" class="btn btn-sm btn-primary">Mostrar</button>
                    </div>
                </div>
                <div class="tab-pane container-grid" id="expedientes">
                    <table class="table">
                        <thead>
                            <tr>
                                <th style="text-align: center;">
                                    Número de expedientes (separados por comas)
                                </th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="display: flex;justify-content: center; gap: 20px;">
                                    <textarea style="width: 50%;" class="form-control" v-model="numerosExpedientes"> </textarea>
                                    <div class="row" style="align-items: center; gap: 5px;">
                                        <label style="margin: 0;" for="">Incluir relacionados</label>
                                        <input type="checkbox" v-model="incluirelacionados">
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="display: flex; justify-content: center;">
                        <button @click="mostrarAlbaranesNumerosExpe" class="btn btn-sm btn-primary">Mostrar</button>
                    </div>
                </div>
                <div class="tab-pane container-grid" id="importcsv">
                    <div>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="text-align: center;">Formato CSV</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="row">
                                        <div class="col-md-6">
                                            <img src="@/assets/img/ejemplo_importacion_albaranes_csv.png" alt="">
                                            <div>
                                                <p>El CSV debe contener las siguientes columnas, separadas por punto y coma (;), por este orden:</p>
                                                <ul>
                                                    <li>Nro. ENCARGO</li>
                                                    <li>Fecha Factura (formato: dd/mm/aaaa)</li>
                                                    <li>Número Factura (opcional. Si vacío, cogerá el siguiente de su línea de facturación) (no poner prefijo/sufijo)</li>
                                                    <li>Importe factura (con IVA)</li>
                                                    <li>Cobrado (SI/NO) (vacío implica NO)</li>
                                                    <li>Fecha cobro</li>
                                                    <li>Cliente: A (Asegurado), C (Compañía), vacío se factura como en el albarán</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <p>Seleccionar serie de facturación para las facturas del CSV</p>
                                            <select style="width: 350px;" class="form-control" v-model="serieseleccionada" id="">
                                                <option value="">Seleccione serie</option>
                                                <option v-for="serie in series" :key="serie.id" :value="serie.id">{{ serie.nombre_serie }}</option>
                                            </select>
                                            <p style="margin-top: 25px;">Seleccionar fichero de tipo CSV</p>
                                            <div class="row" style="gap: 15px;">
                                                <FileUpload mode="basic" :maxFileSize="1000000" @select="subirarchivo($event)" chooseLabel="Elegir Archivo" />
                                                <div class="row" style="align-items: center; gap: 5px;">
                                                    <label for="" style="margin: 0;">Incluir avisos</label>
                                                    <input type="checkbox" v-model="incluiravisos">
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="display: flex; justify-content: center;">
                            <button @click="mostrarAlbaranesCSV" class="btn btn-sm btn-primary">Mostrar</button>
                        </div>
                    </div>
                </div>
                <div class="tab-pane container-grid" id="importcsvld">
                    
                    Importación CSV LD
                
                </div>
                <div class="tab-pane container-grid" id="importpdf">
                    <table class="table">
                        <thead>
                            <tr>
                                <th style="text-align: center;">
                                    Importar expedientes desde el PDF
                                </th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="display: flex;justify-content: center; gap: 20px;">
                                    <FileUpload mode="basic" :maxFileSize="1000000" @select="subirarchivoPdf($event)" chooseLabel="Elegir Archivo" />
                                    <div class="row" style="align-items: center; gap: 5px;">
                                        <label style="margin: 0;" for="">Incluir relacionados</label>
                                        <input type="checkbox" v-model="incluirelacionadospdf">
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="display: flex; justify-content: center;">
                        <button @click="mostrarAlbaranesPDF" class="btn btn-sm btn-primary">Mostrar</button>
                    </div>
                </div>                
            </div>            
        </div>
    </div>
    <div class="card" v-if="albaranesVisibles">
        <div class="card-body">
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th style="text-align: center;">Nº Albarán</th>
                        <th style="text-align: center;">Fecha Albarán</th>
                        <th style="text-align: center;">Expediente</th>
                        <th style="text-align: center;">Cliente</th>
                        <th style="text-align: center;">Importe</th>
                        <th style="text-align: center;">Factura</th>
                        <th style="text-align: center;"><input v-model="checkTodas" type="checkbox"></th>
                        <th style="text-align: center;"></th>
                        <th style="text-align: center;">Eliminar factura</th>
                        <th style="text-align: center;">Abono</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="albaran in albaranes" :key="albaran.id_albaran">
                        <td>{{ albaran.numero }}</td>
                        <td>{{ albaran.fecha }}</td>
                        <td style="display: flex; align-items: center"><button class="btn" @click="irServicio(albaran)">{{ albaran.expediente }}</button><span style="color: rgb(134, 134, 134);">{{ albaran.relacionados }}</span></td>
                        <td>{{ albaran.cliente }}</td>
                        <td>{{ albaran.importe_base }}€</td>
                        <td>{{ albaran.factura }}</td>
                        <td v-if="albaran.factura">
                            
                        </td>
                        <td v-else style="text-align: center;">
                            <input @change="marcado(albaran)" v-model="albaran.marcada" type="checkbox">
                        </td>
                        <td v-if="albaran.factura" style="text-align: center;">
                            <button class="btn" @click="descargarFactura(albaran)" title="Ver factura"><i class="far fa-file-pdf"></i></button>
                        </td>
                        <td v-else>
                            
                        </td>
                        <td v-if="albaran.factura" style="text-align: center;">
                            <button class="btn btn-sm btn-danger" @click="confirmarEliminar(albaran)" title="Eliminar albarán"><i class="fas fa-trash-alt"></i></button>
                        </td>
                        <td v-else>
                            
                        </td>
                        <td v-if="albaran.factura" style="text-align: center;">
                            <button class="btn btn-sm btn-light" style="border: 1px solid grey;">Generar abono</button>
                        </td>
                        <td v-else>
                            
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><strong>{{ importe_base }}€</strong></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div v-if="albaranesCSVVisibles">
        <table class="table">
            <thead>
                <tr>
                    <th>Nº Albarán</th>
                    <th>Fecha Albarán</th>
                    <th>Expediente</th>
                    <th>Cliente</th>
                    <th>Importe</th>
                    <th>Factura</th>
                    <th><input type="checkbox"></th>
                    <th></th>
                    <th>Eliminar factura</th>
                    <th>Abono</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="albaran in albaranesCSV" :key="albaran.id_albaran">
                    <td>{{ albaran.numero }}</td>
                    <td>{{ albaran.fecha }}</td>
                    <td style="display: flex; align-items: center"><button class="btn" @click="irServicio(albaran)">{{ albaran.expediente }}</button><span style="color: rgb(134, 134, 134);">{{ albaran.relacionados }}</span></td>
                    <td>{{ albaran.cliente }}</td>
                    <td>{{ albaran.importe_base }}</td>
                    <td>{{ albaran.factura }}</td>
                    <td v-if="albaran.factura">
                            
                    </td>
                    <td v-else style="text-align: center;">
                        <input @change="marcadoCSV(albaran)" v-model="albaran.marcada" type="checkbox">
                    </td>
                    <td v-if="albaran.factura" style="text-align: center;">
                        <button class="btn" @click="descargarFactura(albaran)" title="Ver factura"><i class="far fa-file-pdf"></i></button>
                    </td>
                    <td v-else>
                        
                    </td>
                    <td v-if="albaran.factura" style="text-align: center;">
                        <button class="btn btn-sm btn-danger" @click="confirmarEliminar(albaran)" title="Eliminar albarán"><i class="fas fa-trash-alt"></i></button>
                    </td>
                    <td v-else>
                        
                    </td>
                    <td v-if="albaran.factura" style="text-align: center;">
                        <button class="btn btn-sm btn-light" style="border: 1px solid grey;">Generar abono</button>
                    </td>
                    <td v-else>
                        
                    </td>
                </tr>
                <tr v-for="error in erroresCSV" :key="error">
                    <!--
                    Mostrar bien la lista de albaranes y errores al importar csv, también la creación de facturas por albarán.
                    -->
                </tr>
            </tbody>
        </table>
    </div>
    <Dialog :style="{ width: '25rem' }" @hide="cerrarForm" header="Eliminar factura" modal
    v-model:visible="dialogeliminar">
        <div>
            <textarea id="" style="width: 100%; margin: 10px 0" v-model="motivoEliminar" placeholder="Indique un motivo"></textarea>
        </div>
        <div style="display: flex; justify-content: end; gap: 15px;">
            <button @click="eliminarAlbaran" class="btn btn-sm btn-primary">Eliminar</button>
            <button @click="cerrarForm" class="btn btn-sm btn-light">Cancelar</button>
        </div>
    </Dialog>
    <Dialog :style="{ width: '25rem' }" @hide="cerrarFormAbonar" header="Abonar factura" modal
    v-model:visible="dialogabonar">
        <div>
            <textarea id="" style="width: 100%; margin: 10px 0" v-model="motivoEliminar" placeholder="Indique un motivo"></textarea>
        </div>
        <div style="display: flex; justify-content: end; gap: 15px;">
            <button @click="abonarFactura" class="btn btn-sm btn-primary">Abonar</button>
            <button @click="cerrarFormAbonar" class="btn btn-sm btn-light">Cancelar</button>
        </div>
    </Dialog>
    <div class="card" v-if="albaranesVisibles">
        <div class="card-header">
            <p class="card-title">Crear factura</p>
        </div>
        <div class="card-body">
            <table class="table">
                <thead>
                    <tr>
                        <th>Destinatario</th>
                        <th>Empresa emisora</th>
                        <th>Forma pago</th>
                        <th>I.V.A.</th>
                        <th>Nº Factura</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <select v-model="destinatarioFactura" class="form-control" name="" id="">
                                <option value="">Seleccione cliente</option>
                                <option v-for="comp in Object.entries(destinatariosFactura)" :key="comp[0]" :value="comp[0]">{{ comp[1].nombre }}</option>
                            </select>
                        </td>
                        <td>
                            <select v-model="empresaFactura" class="form-control" name="" id="">
                                <option value="">Seleccione empresa</option>
                                <option v-for="empr in empresasFactura" :key="empr.id" :value="empr.id">{{ empr.nombre }}</option>
                            </select>
                        </td>
                        <td>
                            <select v-model="formapagoFactura" class="form-control" name="" id="">
                                <option v-for="formas in formaspagoFactura" :key="formas">{{ formas }}</option>
                            </select>
                            <select v-model="cuentaFactura" class="form-control" name="" id="">
                                <option value="">Seleccione cuenta</option>
                                <option v-for="cuenta in cuentasFactura" :value="cuenta.id_cuenta" :key="cuenta.id_cuenta">{{ cuenta.iban_cuenta }}-{{ cuenta.iban2_cuenta }}-{{ cuenta.entidad_cuenta }}-{{ cuenta.oficina_cuenta }}-{{ cuenta.control_cuenta }}-{{ cuenta.numero_cuenta }}</option>
                            </select>
                        </td>
                        <td style="display: flex;">
                            <select class="form-control" v-model="ivaFactura" id="">
                                <option v-for="iva in ivasFactura" :key="iva" :value="iva">{{ iva }}%</option>
                            </select>
                        </td>
                        <td>
                            <div class="row" v-if="destinatarioFactura!=''">
                                <select style="width: 200px;" class="form-control" v-model="serieFactura" id="">
                                    <option v-for="serie in Object.values(seriesFactura)" :key="serie.id" :value="serie">{{ serie.nombre }}</option>
                                </select>
                                <input style="width: 100px;" class="form-control" type="text" v-model="numeroFactura">
                                <span style="font-size: x-large;">/</span>
                                <select style="width: 100px;" class="form-control" v-model="añoFactura" id="">
                                    <option v-for="año in añosFacturas" :key="año" :value="año">{{ año }}</option>
                                </select>
                            </div>
                            <input style="width: 200px;" v-if="destinatarioFactura!=''" class="form-control" type="date" v-model="fechaFactura">
                        </td>
                        <td style="text-align: center;" v-if="!facturaGenerada">
                            <button class="btn btn-sm btn-primary" @click="generarFactura">Generar una factura</button>
                        </td>
                        <td style="display: flex; align-items: center;" v-else>
                            <p style="margin: 0;">Ver factura</p><button class="btn" @click="descargarFacturaCreada()" title="Ver factura"><i class="far fa-file-pdf"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="table">
                <thead>
                    <tr>
                        <th style="text-align: center;">Albaranes seleccionados</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="totales in Object.entries(totalesFactura)" :key="totales[0]">
                        <td style="display: grid; grid-template-columns: 1fr 5fr">
                            <div style="text-align: center;">
                               <p>Total({{ totales[0] }}%)</p>
                            </div>
                            <div>
                                <p>{{ totales[1].total }}€</p>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>    
</template>
<script>
import $ from 'jquery';
import MultiSelect from 'primevue/multiselect';
import { PwgsApi } from '../../../services/PwgsApi';
import FileUpload from 'primevue/fileupload';
export default{    
    data(){
        return {
            companias:null,
            companiasSeleccionadas:[],
            empresas:null,
            empresaselec:'',
            fecha_inicio:'',
            fecha_fin:'',
            facturadoselec:'',
            busquedaNombre:'',
            numeroAlbaran:'',
            importe_total:0,
            importe_base:0,
            albaranes:[],
            albaranesVisibles:false,
            numerosExpedientes:'',
            incluirelacionados:false,
            incluirelacionadospdf:false,
            mostrarCSV:false,
            mostrarCSVLD:false,
            mostrarPDF:false,
            series:[],
            serieseleccionada:'',
            incluiravisos:false,
            archivoSubidocsv:'',
            archivoSubidopdf:'',
            checkTodas:false,
            facturaaeliminar:null,
            dialogeliminar : false,
            motivoEliminar:'',
            dialogabonar:false,
            ivaFactura:'',
            ivasFactura:[],
            destinatarioFactura:'',
            destinatariosFactura:[],
            fechaFactura:'',
            añosFacturas:[],
            numeroFactura:'',
            añoFactura:'',
            formaspagoFactura:[],
            formapagoFactura:'',
            empresasDestinatario:[],
            compDestinatario:[],
            seriesFactura:[],
            serieFactura:'',
            cuentasFactura:[],
            cuentaFactura:'',
            empresaFactura:'',
            empresasFactura:[],
            año_actual:'',
            totalesFactura:'',
            albaranesMarcados:[],
            dia_actual:'',
            facturaGenerada:false,
            urlFacturaCreada:'',
            albaranesCSVVisibles:false,
            albaranesCSV:[],
            erroresCSV:[],
        }
    },
    components:{
        MultiSelect,
        FileUpload,
    },
    computed: {     
    },
    methods:{
        async generarFactura(){
            const api = new PwgsApi();
            if(this.albaranesMarcados.length < 0){
                alert('Debe seleccionar algún albarán.')
                return;
            }
            if(this.destinatarioFactura==''){
                alert('Debe seleccionar alguna compañía.')
                return;
            }
            if(this.empresaFactura==''){
                alert('Debe seleccionar alguna empresa.')
                return;
            }
            if(this.formapagoFactura == 'Transferencia Bancaria' && this.cuentaFactura == ''){
                alert('Debe seleccionar alguna cuenta bancaria.')
                return;
            }
            const body = {ids_albaranes: this.albaranesMarcados, id_compania:this.destinatarioFactura, id_empresa:this.empresaFactura,
                forma_pago:this.formapagoFactura,tipo_iva:this.ivaFactura, id_serie:this.serieFactura.id, numero:this.numeroFactura,
                yead: this.añoFactura, fecha_factura:this.fechaFactura}
            if(this.forma_pago == 'Transferencia Bancaria'){
                body.cuenta_bancaria=this.cuentaFactura;
            }
            const resp = await api.post('facturacion-albaranes/facturar', body);
            this.facturaGenerada = true;
            this.urlFacturaCreada = resp.url_factura_pdf;
            this.actualizarLista();
        },
        actualizarLista(){
            console.log('actualizarLista');
            var aux = this.albaranes;
            this.albaranes = [];
            setTimeout(() => {
                this.albaranes = aux;
            }, 1000);
           
        },
        marcado(albaran){
            console.log('entrachange', albaran);
            if(albaran.marcada){
                this.albaranesMarcados.push(albaran.id_albaran);
                console.log(this.albaranesMarcados);
                this.destinatarioFactura = '';
                this.obtenerDatosFactura();
            }else{
                let index = this.albaranesMarcados.indexOf(albaran.id_albaran);
                if (index > -1) {
                    this.albaranesMarcados.splice(index, 1);
                    console.log(this.albaranesMarcados);
                }
                this.destinatarioFactura = '';
                this.obtenerDatosFactura();
            }
        },
        irServicio(albaran){
            this.$router.push({
                name: 'Servicio',
                params: {
                    id:albaran.id_servicio
                }
            });
        },
        confirmarEliminar(albaran) {
            this.facturaaeliminar = albaran;
            this.dialogeliminar=true;
        },
        cerrarForm(){
            this.dialogeliminar = false;
            this.motivoEliminar='';
            this.facturaaeliminar=null;
        },
        cerrarFormAbonar(){

        },
        async eliminarAlbaran(){
            if(this.motivoEliminar==''){
                alert('Debe indicar un motivo.')
                return;
            }
            const api = new PwgsApi();
            const params = {motivo:this.motivoEliminar};
            await api.deletebody('facturas/' + this.facturaaeliminar.id_factura, params);
            this.facturaaeliminar.marcada = false;
            this.facturaaeliminar.factura = null;
            var albaranEncontrado = this.albaranes.filter(albaran => albaran == this.facturaaeliminar);
            albaranEncontrado.marcada = false;
            albaranEncontrado.factura = null;
            this.cerrarForm();
        },
        descargarFactura(albaran){
            var urlglobal = localStorage.getItem('direccion');
            var url = '';
            if(urlglobal == 'null.pwgs.app'){
                url  = "https://desarrollofront.pwgs.app/"+albaran.url_factura_pdf;
            }else{
                url  = "https://"+urlglobal+'/'+albaran.url_factura_pdf;
            }
            window.open(url, "Factura", "width=800, height=600");
        },
        descargarFacturaCreada(){
            var urlglobal = localStorage.getItem('direccion');
            var url = '';
            if(urlglobal == 'null.pwgs.app'){
                url  = "https://desarrollofront.pwgs.app/"+this.urlFacturaCreada;
            }else{
                url  = "https://"+urlglobal+'/'+this.urlFacturaCreada;
            }
            window.open(url, "Factura", "width=800, height=600");
        },
        marcarTodas(){
            if(!this.checkTodas){
                for(let albaran of this.albaranes){
                    console.log('entrafor', );
                    if(!albaran.factura){
                        console.log('entraalbaran', albaran);
                        albaran.marcada = false;
                        this.albaranesMarcados = [];
                    }
                }
            }else{
                for(let albaran of this.albaranes){
                    if(!albaran.factura){
                        albaran.marcada = true;
                        this.albaranesMarcados.push(albaran.id_albaran);
                    }
                }
            }
            this.obtenerDatosFactura();
        },
        subirarchivo(event) {
            console.log('subirarc',event);
            this.archivoSubidocsv = event.files[0];
            console.log('subirarc2',this.archivoSubidocsv);
        },
        subirarchivoPdf(event) {
            console.log('subirarc',event);
            this.archivoSubidopdf = event.files[0];
            console.log('subirarc2',this.archivoSubidocsv);
        },
        async mostrarAlbaranesBusqueda(){
            if(this.companiasSeleccionadas.length==0){
                alert("Debe seleccionar alguna compañía");
                return;
            }else{
                var companias = [];
                for(let compa of this.companiasSeleccionadas){
                    companias.push(compa.id);
                }
            }
            this.albaranes = [];
            const api = new PwgsApi();
            var body = {id_empresa: this.empresaselec, ids_companias:companias, fecha_inicio:this.fechaesp(this.fecha_inicio), fecha_fin:this.fechaesp(this.fecha_fin)};
            if(this.numeroAlbaran){
                body.numero = this.numeroAlbaran;
            }
            if(this.busquedaNombre){
                body.nombre = this.busquedaNombre;
            }
            if(this.facturadoselec){
                body.facturado = this.facturadoselec;
            }
            const resp = await api.post('facturacion-albaranes/busqueda',body);
            for(let alb of Object.entries(resp)){
                if(alb[0] == 'importe_totales'){
                    this.importe_total = alb[1];
                }else if(alb[0] == 'importe_bases'){
                    this.importe_base = parseFloat(alb[1]).toFixed(2);
                }else{
                    if(alb[1].factura){
                        alb[1].marcada = false;
                    }
                    this.albaranes.push(alb[1]);
                }
            }
            this.albaranesMarcados = [];
            this.facturaGenerada = false;
            this.urlFacturaCreada = '';
            this.obtenerDatosFactura();
            this.albaranesVisibles = true;
        },
        async obtenerDatosFactura(){
            const api = new PwgsApi();
            console.log('albasmarcas',this.albaranesMarcados);            
            if(this.albaranesMarcados.length > 0){
                if(this.destinatarioFactura!=''){
                    const siguiente = await api.post('/facturacion-albaranes/siguiente-numero',{ids_albaranes:this.albaranesMarcados,year:this.añoFactura,id_compania:this.destinatarioFactura}); 
                    this.formaspagoFactura = siguiente.formas_pago;
                    this.formapagoFactura = siguiente.forma_pago_defecto;
                    this.cuentasFactura = siguiente.cuentas_bancarias;
                    this.ivasFactura = siguiente.tipos_iva;
                    for(var iva of this.ivasFactura){
                        this.ivaFactura = iva;
                        break;
                    }
                    this.totalesFactura = siguiente.totales;
                    this.empresasFactura = siguiente.empresas;
                    this.destinatariosFactura = siguiente.companias;                    
                    for(var sers of Object.values(siguiente.series_compania_empresa)){
                        console.log('sers',sers);
                        this.seriesFactura = sers[0].series;
                    }
                    console.log('seriesfact',this.seriesFactura);
                    for(var serie of Object.values(this.seriesFactura)){
                        this.serieFactura = serie;
                        break;
                    }
                }else{
                    const siguiente = await api.post('/facturacion-albaranes/siguiente-numero',{ids_albaranes:this.albaranesMarcados,year:this.añoFactura}); 
                    this.formaspagoFactura = siguiente.formas_pago;
                    this.formapagoFactura = siguiente.forma_pago_defecto;
                    this.cuentasFactura = siguiente.cuentas_bancarias;
                    this.ivasFactura = siguiente.tipos_iva;
                    for(var iv of this.ivasFactura){
                        this.ivaFactura = iv;
                        break;
                    }
                    this.totalesFactura = siguiente.totales;
                    this.empresasFactura = siguiente.empresas;
                    this.destinatariosFactura = siguiente.companias;
                }
            }else{
                const siguiente = await api.post('/facturacion-albaranes/siguiente-numero',{ids_albaranes:[],year:this.añoFactura}); 
                this.formaspagoFactura = siguiente.formas_pago;
                this.formapagoFactura = siguiente.forma_pago_defecto;
                this.cuentasFactura = siguiente.cuentas_bancarias;
                this.ivasFactura = siguiente.tipos_iva;
                for(var ia of this.ivasFactura){
                        this.ivaFactura = ia;
                        break;
                    }
                this.totalesFactura = siguiente.totales;
                this.empresasFactura = [];
                this.empresaFactura = '';
                this.destinatariosFactura = [];
                this.destinatarioFactura = '';
            }
            this.fechaFactura = this.fechaing(this.dia_actual);
        },
        obtenerNumero(){
            if(this.serieFactura != ''){
                this.numeroFactura = this.serieFactura.numero;
            }else{
                this.numeroFactura = '';
            }
        },
        async mostrarAlbaranesCSV(){
            this.albaranesCSV = [];
            this.erroresCSV = [];
            if(this.companiasSeleccionadas.length==0){
                alert("Debe seleccionar alguna compañía");
                return;
            }else{
                var companias = '';
                console.log('compssel', this.companiasSeleccionadas);
                for(let compa of this.companiasSeleccionadas){
                    console.log('compa', compa);
                    if(companias == ''){
                        companias = compa.id;    
                    }else{
                        companias = companias + ', '+compa.id;
                    }
                }
            }
            if(this.archivoSubidocsv == ''){
                alert("Debe seleccionar algún archivo csv");
                return;
            }
            console.log('archcsv',this.archivoSubidocsv);
            this.albaranes = [];
            const api = new PwgsApi();
            const formData = new FormData();
            formData.append('id_empresa', this.empresaselec);
            formData.append('ids_companias', companias);
            formData.append('csv', this.archivoSubidocsv);
            const resp = await api.post('facturacion-albaranes/importacion-csv',formData);
            console.log('resp', resp);
            for(let alb of Object.entries(resp.albaranes)) {
                if(alb[0] == 'importe_bases'){
                    this.importe_base_csv=alb[1];
                }else if(alb[0] == 'importe_totales'){
                    console.log();
                }else{
                    if(alb[1].factura){
                        alb[1].marcada = false;
                    }
                    this.albaranesCSV.push(alb[1]);
                }
            }
            this.erroresCSV = resp.errores;
            this.albaranesCSVVisibles = true;
        },
        async mostrarAlbaranesPDF(){
            if(this.companiasSeleccionadas.length==0){
                alert("Debe seleccionar alguna compañía");
                return;
            }else{
                var companias = [];
                for(let compa of this.companiasSeleccionadas){
                    companias.push(compa.id);
                }
            }
            if(this.archivoSubidopdf == ''){
                alert("Debe seleccionar algún archivo PDF");
                return;
            }
            console.log('archpdf',this.archivoSubidopdf);
            this.albaranes = [];

            let formData = new FormData();
            formData.append('id_empresa', this.empresaselec);
            formData.append('ids_companias', JSON.stringify(companias));
            formData.append('pdf', this.archivoSubidopdf);
            formData.append('incluir_relacionados', this.incluirelacionadospdf);

            const api = new PwgsApi();
            const resp = await api.post('facturacion-albaranes/importacion-pdf', formData);
            console.log('resp', resp);
            //this.albaranesVisibles = true;
        },
        async mostrarAlbaranesNumerosExpe(){
            if(this.companiasSeleccionadas.length==0){
                alert("Debe seleccionar alguna compañía");
                return;
            }else{
                var companias = [];
                for(let compa of this.companiasSeleccionadas){
                    companias.push(compa.id);
                }
            }
            this.albaranes = [];
            const api = new PwgsApi();
            var body = {id_empresa:this.empresaselec, ids_companias:companias, fecha_inicio:this.fechaesp(this.fecha_inicio), fecha_fin:this.fechaesp(this.fecha_fin)};
            if(this.numerosExpedientes){
                body.expedientes = this.numerosExpedientes.split(";");
            }
            if(this.incluirelacionados){
                body.incluir_relacionados = 1;
            }
            const resp = await api.post('facturacion-albaranes/expedientes', body);
            console.log('respusetaNumeros',resp);
            for(let alb of Object.entries(resp)){
                if(alb[0] == 'importe_totales'){
                    this.importe_total = alb[1];
                }else if(alb[0] == 'importe_bases'){
                    this.importe_base = alb[1];
                }else{
                    this.albaranes.push(alb[1]);
                }
            }
            this.albaranesMarcados = [];
            this.facturaGenerada = false;
            this.urlFacturaCreada = '';
            this.obtenerDatosFactura();
            this.albaranesVisibles = true;
        },
        comprobarPestañas(){
            this.mostrarCSV=false;
            this.mostrarCSVLD=false;
            this.mostrarPDF=false;
            this.series = [];
            this.incluiravisos=false;
            this.serieseleccionada = '';
            $('.nav-link').removeClass('active');
            $(`[href="#busqueda"]`).addClass('active');
            $('.tab-pane').removeClass('active');
            $(`#busqueda`).addClass('active');
            if(this.companiasSeleccionadas.length > 0){
                var numero = this.companiasSeleccionadas.length;
                var csv = 0;
                var csVLD = 0;
                var pdf = 0;
                for(let compa of this.companiasSeleccionadas){
                    if(compa.tipos_importacion_disponibles.includes('importacion-csv')){
                        csv++;
                    }
                    if(compa.tipos_importacion_disponibles.includes('importacion-csv-ld')){
                        csVLD++;
                    }
                    if(compa.tipos_importacion_disponibles.includes('importacion-pdf')){
                        pdf++;
                    }
                    for(let linea of compa.lineas_facturacion){
                        this.series.push(linea);
                    }
                }
                if(csv == numero){
                    this.mostrarCSV = true;
                }
                if(csVLD == numero){
                    this.mostrarCSVLD = true;
                }
                if(pdf == numero){
                    this.mostrarPDF = true;
                }
            }
        },
        async obtenerDatos(){
            const api = new PwgsApi();            
            this.empresas = await api.get('empresas/todas');
        },
        async obtenerCompanias(){
            const api = new PwgsApi();
            const resp = await api.get('facturacion-albaranes/companias/'+this.empresaselec);
            this.companias = resp.datos;
        },
        fechaActual() {
        const hoy = new Date();
        this.fecha_inicio = this.fechaing(hoy.toLocaleDateString());
        this.fecha_fin = this.fechaing(hoy.toLocaleDateString());
        this.añoActual();
        },
        añoActual() {
            var hoy = new Date();
            this.dia_actual = hoy.toLocaleDateString();
            this.año_actual = new Date().getFullYear();
            this.añosFacturas.push(this.año_actual);
            this.añosFacturas.push(this.año_actual-1);
            this.añosFacturas.push(this.año_actual-2);
            this.añoFactura = this.año_actual;            
        },
        fechaing(fecha) {
        const [dia, mes, año] = fecha.split("/");
        return `${año}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;
        },

        fechaesp(fecha) {
        const [año, mes, dia] = fecha.split("-");
        return `${dia}/${mes}/${año}`;
        },
    },
    mounted(){
        this.obtenerDatos();
        this.fechaActual();
    },
    watch:{
        destinatarioFactura(){
            this.obtenerDatosFactura();
        },
        serieFactura(){
            this.obtenerNumero();
        },
        añoFactura(){
            this.obtenerDatosFactura();
        },
        checkTodas(){
            this.marcarTodas();
        },
        empresaselec(value){
            this.companias = null;
            this.companiasSeleccionadas = [];
            if(value){
                this.obtenerCompanias();
            }
        },
        companiasSeleccionadas(){
            this.comprobarPestañas();
        }
    }
}
</script>